<template>
  <div>
    <home-header activeIndex="0" pageTitle="报名流程" />

    <div class="wrap">
      <a-form :form="form" ref="mbxxForm">
        <div class="section">
          <div class="part">
            <div class="title">
              <span class="line"></span>
              <span>学生信息</span>
            </div>
          </div>

          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="学生姓名">
                <a-input
                  size="large"
                  type="text"
                  disabled
                  v-decorator="['xm']"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="性别">
                <a-input
                  size="large"
                  type="text"
                  disabled
                  v-decorator="['xb']"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="证件号码">
                <a-input
                  size="large"
                  type="text"
                  disabled
                  v-decorator="['zjh']"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="监护人姓名">
                <a-input
                  size="large"
                  type="text"
                  disabled
                  v-decorator="['jhr']"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="监护人手机号">
                <a-input
                  size="large"
                  type="text"
                  disabled
                  v-decorator="['jhrdh']"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-row>
        </div>

        <div class="section">
          <div class="part">
            <div class="title">
              <span class="line"></span>
              <span>选报学校</span>
            </div>
          </div>

          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="均衡入学意向学校">
                <a-select
                  size="large"
                  placeholder="请选择均衡入学意向学校"
                  @change="schoolChange"
                  v-decorator="[
                    'twiceYxSchoolId',
                    {
                      rules: [
                        { required: true, message: '请选择均衡入学意向学校' },
                      ],
                      validateTrigger: 'change',
                    },
                  ]"
                >
                  <a-select-option
                    v-for="item in schoolList"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item
                label="均衡入学意向关联派位"
                v-if="glAbandon || glConfirm"
              >
                <div v-if="glAbandon" class="tip">已放弃关联派位<br /></div>
                <div v-if="glConfirm">
                  <div class="glyh-list" :style="{ background: '#fff' }">
                    <div v-for="item in glyhList" :key="item.id">
                      <span>{{ item.stuXm }}：</span>
                      <span>{{ item.stuZjh }}</span>
                    </div>
                  </div>
                </div>
              </a-form-item>
            </a-col>

            <!-- 关联排位start -->
            <Glyh
              ref="glyh"
              serial="一"
              @getGlyhIds="glyhOK"
              @abandon="glyhCancel"
            />
            <!-- 关联排位end -->
          </a-row>
        </div>

        <div class="reminder-footer">
          <a-button
            @click="backHome"
            shape="round"
            class="reminder-footer-btn btn-cancel"
          >
            取消
          </a-button>
          <a-button
            @click="formSubmin"
            type="primary"
            shape="round"
            class="reminder-footer-btn btn-ok"
          >
            提交
          </a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import pick from "lodash.pick";
import HomeHeader from "@/components/HomeHeader";
import Glyh from "./glyh/glyh";
import {
  getDetail,
  submitSecondSelectionReport,
  getTwiceSchoolList,
  getIfGlyh,
} from "@/api/form";

export default {
  name: "SecondSelectionReport",
  components: {
    HomeHeader,
    Glyh,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      detailInfo: {},
      schoolList: [],

      // 关联摇号
      glAbandon: false, // 确认
      glConfirm: false, // 取消
      glyhList: [], // 关联学生列表
      glRegIds: "",
    };
  },
  methods: {
    // 确定关联摇号
    glyhOK(values, list) {
      console.log(values, list);

      this.glRegIds = values;
      this.glyhList = list;
      this.glConfirm = true;
    },
    // 取消关联摇号
    glyhCancel() {
      this.glRegIds = "";
      this.glyhList = [];
      this.glAbandon = true;
    },

    schoolChange(e) {
      this.clearGlyh();

      this.getIfGlyh(e);
    },

    clearGlyh() {
      this.glAbandon = false; // 确认
      this.glConfirm = false; // 取消
      this.glyhList = []; // 关联学生列表
      this.glRegIds = "";
    },

    // 是否显示关联派位
    getIfGlyh(yxSchoolId) {
      getIfGlyh({
        yxSchoolId: yxSchoolId,
        rank: 4,
      }).then((res) => {
        if (res.data.length > 0) {
          this.$refs.glyh.show(res.data);
        }
      });
    },

    getSchoolList() {
      getTwiceSchoolList().then((res) => {
        this.schoolList = res.data;
      });
    },

    // 提交
    formSubmin() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let params = {
            id: this.detailInfo.id,
            twiceYxSchoolId: values.twiceYxSchoolId,
          };

          this.schoolList.forEach((item) => {
            if (item.id == values.twiceYxSchoolId) {
              params.twiceYxSchoolName = item.name;
            }
          });

          params.twiceGlRegIds = this.glRegIds || "";

          submitSecondSelectionReport(params).then((res) => {
            console.log(res);
            if (res.code == 0) {
              this.$message.success(res.description);

              setTimeout(() => {
                this.$router.push({
                  path: "/home",
                  query: { isShowXxdjInfo: true },
                });
              }, 1000);
            } else {
              this.$message.error(res.description);
            }
          });
        }
      });
    },
    // 取消
    backHome() {
      this.$router.push({ path: "/home", query: { isShowXxdjInfo: true } });
    },

    // 回显学生信息
    getDetailInfo() {
      getDetail().then((res) => {
        this.detailInfo = res.data;
        this.setFormData();
      });
    },
    setFormData() {
      const formData = pick(this.detailInfo, [
        "id",
        "xb",
        "xm",
        "zjh",
        "familyList",
      ]);

      let params = {
        xb: formData.xb == 1 ? "男" : "女",
        xm: formData.xm,
        zjh: formData.zjh,
        jhr: formData.familyList ? formData.familyList[0].fXm : "",
        jhrdh: formData.familyList ? formData.familyList[0].lxdh : "",
      };

      this.$nextTick(() => {
        this.form.setFieldsValue(params);
      });
    },
  },
  mounted() {
    this.getDetailInfo();
    this.getSchoolList();
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 1000px;
  min-height: 448px;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: -50px auto 30px;
  position: relative;
  z-index: 2;
  padding: 32px 77px;
}

.section {
  .part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f7f7f7;
    padding: 8px 16px;
    margin-bottom: 12px;
    margin-top: 28px;
  }
  .title {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .line {
    display: inline-block;
    width: 3px;
    height: 16px;
    background: #35ab61;
    border-radius: 2px;
    margin-right: 6px;
  }
  .scan {
    font-size: 14px;
    color: #35ab61;
    cursor: pointer;
  }
  .del {
    font-size: 14px;
    color: #ff5454;
  }
  .icon {
    margin-right: 6px;
  }
  .lookDemo {
    font-size: 14px;
    color: #35ab61;
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 8px;
  }
}

.reminder-footer {
  width: 350px;
  display: flex;
  justify-content: space-around;
  padding: 0 40px;
  margin: 0 auto;

  .reminder-footer-btn {
    padding: 0 40px;
  }
}
.tip {
  color: #ff7575;
  font-size: 14px;
}
</style>
